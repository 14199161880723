import React from "react";

import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import PageHeader from "../components/PageHeader";

const Project = ({ pageContext }) => {
    const {
        page_header: pageHeader,
        title,
        acfPrivacyPolicy,
    } = pageContext;

    const pageTitle = title || 'Privacy Policy';
    const pageDescription = `Privacy Policy Page`;
    const pageContent = acfPrivacyPolicy?.content;

    useBeaconWidget(beaconWidgetIds.GENERAL);

    return (
        <DefaultLayout isHeaderWithBlackText={true}>
            <DefaultHelmet title={pageTitle} description={pageDescription} />

            <PageHeader {...pageHeader} isGeneral={true} />

            <div
                className="rte container privacy-policy"
                dangerouslySetInnerHTML={{__html: pageContent}}
            />
        </DefaultLayout>
    )
};

export default Project;
